import { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Router,
  BrowserRouter,
  RouterProps,
  Route,
  Switch,
} from 'react-router-dom';
import { NotificationProvider } from 'react-ui-kit-exante';

import { Offsets } from 'pages/Offsets/Offsets';
import {
  EmirTradesPage,
  EmirPositionsPage,
  EmirScriptsPage,
  DetailPage,
} from 'pages/emir';
import { Main, Dashboard } from 'pages/main';
import {
  MifidReportsPage,
  MifidEntriesPage,
  MifidEntryPage,
} from 'pages/mifid';
import { SftrTradesPage, SftrReportsPage } from 'pages/sftr';
import { getBaseName } from 'utils/routingHelpers';

import { ErrorFallback } from '../ErrorFallback';
import { AppMenu } from '../Menu';
import 'styles/index.css';

const baseName = getBaseName();
export const Application: FC = () => {
  const isInsideRun = !!window.runUIhistoryContainer;
  const urlPrefix = isInsideRun ? '/reporting' : '';

  const runHistory =
    window.runUIhistoryContainer as unknown as RouterProps['history'];

  const routerInnerContainer = (
    <div className="mui-container-fluid">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route
            exact
            path={`${urlPrefix}/mifid/reports`}
            component={MifidReportsPage}
          />
          <Route
            path={`${urlPrefix}/mifid/reports/:reportId`}
            component={MifidEntriesPage}
          />
          <Route
            path={`${urlPrefix}/mifid/entry/:entryId`}
            component={MifidEntryPage}
          />
          <Route path={`${urlPrefix}/sftr/trades`} component={SftrTradesPage} />
          <Route
            path={`${urlPrefix}/sftr/reports`}
            component={SftrReportsPage}
          />

          <Route path={`${urlPrefix}/emir/trades`} component={EmirTradesPage} />
          <Route
            path={`${urlPrefix}/emir/trade/:tradeId`}
            component={DetailPage}
          />
          <Route
            path={`${urlPrefix}/emir/positions`}
            component={EmirPositionsPage}
          />
          <Route
            path={`${urlPrefix}/emir/position/:positionId`}
            component={DetailPage}
          />
          <Route
            path={`${urlPrefix}/emir/scripts`}
            component={EmirScriptsPage}
          />
          <Route path={`${urlPrefix}/dashboard`} component={Dashboard} />
          <Route path={`${urlPrefix}/offsets`} component={Offsets} />
        </Switch>
      </ErrorBoundary>
    </div>
  );

  return (
    <>
      <NotificationProvider />
      {isInsideRun ? (
        <Router history={runHistory}>{routerInnerContainer}</Router>
      ) : (
        <BrowserRouter basename={baseName}>
          <AppMenu />
          {routerInnerContainer}
        </BrowserRouter>
      )}
    </>
  );
};
